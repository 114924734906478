@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --radius: 12px;
  --background: 216, 38%, 95%;
  --brand-primary: 225, 39%, 51%;
  --brand-secondary: 192, 74%, 60%;
  --brand-accent: 225, 39%, 51%;
}

@media (prefers-color-scheme: dark) {
  :root {
    --background: 223, 32%, 26%;
    --brand-accent: 192, 74%, 60%;
  }
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}
